import { render, staticRenderFns } from "./MarkdownIt.vue?vue&type=template&id=6a8c8586&scoped=true"
import script from "./MarkdownIt.vue?vue&type=script&lang=js"
export * from "./MarkdownIt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a8c8586",
  null
  
)

export default component.exports